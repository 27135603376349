import {Component, OnInit} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {AuthService} from '../core/auth/auth.service';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent implements OnInit{

  isAuth = true;


  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  menuItems = ['notifications', 'gameplay', 'gains', 'users', 'in-app-purchase', 'set-admin'];

  constructor(private breakpointObserver: BreakpointObserver,
              private auth: AuthService) {

  }

  ngOnInit(): void {
    this.auth.isAuthSubject.subscribe(auth => {
      this.isAuth = auth;
    });
  }

}
