import { Component, OnInit } from '@angular/core';
import {AngularFireFunctions} from '@angular/fire/functions';
import {DialogService} from "../../core/dialog.service";
import {MatSnackBar} from "@angular/material/snack-bar";

export interface Task {
  name: string;
  completed: boolean;
  subtasks?: SubTask[];
}

export interface SubTask {
  name: string;
  topic: string;
  completed: boolean;
  title: string;
  body: string;
}

export interface Notification {
  title: string;
  body: string;
  lang: string;
}

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {

  allComplete = false;

  task: Task = {
    name: 'All',
    completed: true,
    subtasks: [
      {name: 'English', topic: 'en', completed: true, title: null, body: null },
      {name: 'French', topic: 'fr', completed: true, title: null, body: null }
    ]
  };

  constructor(private cf: AngularFireFunctions,
              private dialog: DialogService,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }


  updateAllComplete(): void {
    this.allComplete = this.task.subtasks != null && this.task.subtasks.every(t => t.completed);
  }

  someComplete(): boolean {
    if (this.task.subtasks == null) {
      return false;
    }
    return this.task.subtasks.filter(t => t.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean): void {
    this.allComplete = completed;
    if (this.task.subtasks == null) {
      return;
    }
    this.task.subtasks.forEach(t => t.completed = completed);
  }

  send(): void {
    this.dialog.openDialog().then(() => {
      const notifications: Notification[] = [];

      this.task.subtasks.forEach(sub => {
        if (sub.completed && sub.body !== null && sub.title !== null) {
          notifications.push({
            title: sub.title,
            body: sub.body,
            lang: sub.topic
          });
        }
      });

      if (notifications !== []) {
        const callable = this.cf.httpsCallable('sendNotifications');
        const obs = callable({notifications});

        obs.subscribe(() => {
            this.openSnackBar('Successfully send notifications');
          },
          error => {
            this.openSnackBar('Error while sending notifications: ' + error.message);
          });
      }
    });
  }

  openSnackBar(msg): void {
    this.snackBar.open(msg, null, {duration: 3000});
  }

}
