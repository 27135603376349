<h1>Users</h1>
<form action="" class="example-form">

  <mat-form-field class="example-full-width">
    <mat-label>Uid</mat-label>
    <input matInput name="uid" [(ngModel)]="uid" placeholder="Uid" >
  </mat-form-field>
  <br>
  <mat-form-field class="example-full-width">
    <mat-label>Is Admin ?</mat-label>
    <mat-select name="isAdmin" [(ngModel)]="isAdmin">
      <mat-option [value]="true">True</mat-option>
      <mat-option [value]="false">False</mat-option>
    </mat-select>
  </mat-form-field>
  <div class="sp-between">
    <button (click)="setAdmin()" class="btn">Set</button>
  </div>
</form>
