export interface RoundConfig {
    variant: string;
    concernedPlayer: string;
}

export interface GameModeConfig {
    addBotsDuringWaiting: boolean; // NEW
    betRanges: BetRangeConfig[]; // NEW
    gameModeName: string;
    numberOfPlayers: number;
    numberOfRounds: number;
    // buyInValue: number;
    timeWaitingForPlayers: number;
    timeToBet: number;
    timePerRound: number;
    timePerGame: number;
    color: string;
    // winPrices: number[];
    roundsComposition: RoundConfig[];
}

export interface BetRangeConfig {
    buyInValue: number;  // NEW
    max: number;
    min: number;
    startingStack: number;
    forbiddenIntervals?: ForbiddenInterval[];
    winPrices: number[]; // NEW
}

export interface ForbiddenInterval {
    max: number;
    min: number;
}

export interface AppConfigRules {
    version: string;
    tournament: {
        gameModes: GameModeConfig[]; // classic / crazy / onFire
    };
    faceToFace: {
        gameModes: GameModeConfig[];
    };
    privateTable: {
      turns: number[];
    };
}


export const emptyConfig: AppConfigRules = {
  version: null,
  tournament: {
    gameModes: [
      {
        addBotsDuringWaiting: false,
        betRanges: [
          {
            buyInValue: null,
            min: null,
            max: null,
            startingStack: null,
            forbiddenIntervals: [
              {
                min: null,
                max: null,
              }
            ],
            winPrices: [],
          },
        ],
        gameModeName: '',
        numberOfPlayers: null,
        numberOfRounds: null,
        timeWaitingForPlayers: null,
        timeToBet: null,
        timePerRound: null,
        timePerGame: null,
        color: '',
        roundsComposition: [
          {
            variant: '',
            concernedPlayer: '',
          }
        ],
      }
    ],
  },
  faceToFace: {
    gameModes: [
      {
        addBotsDuringWaiting: false,
        betRanges: [
          {
            buyInValue: null,
            min: null,
            max: null,
            startingStack: null,
            forbiddenIntervals: [
              {
                min: null,
                max: null,
              }
            ],
            winPrices: [],
          },
        ],
        gameModeName: '',
        numberOfPlayers: null,
        numberOfRounds: null,
        timeToBet: null,
        timeWaitingForPlayers: null,
        timePerRound: null,
        timePerGame: null,
        color: '',
        roundsComposition: [
          {
            variant: '',
            concernedPlayer: '',
          }
        ],
      },
    ],
  },
  privateTable: {
    turns: [],
  },
};
