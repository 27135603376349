<h1>Send notifications</h1>
<section class="example-section">
  <span class="example-list-section">
    <mat-checkbox class="example-margin"
                  [checked]="allComplete"
                  [indeterminate]="someComplete()"
                  (change)="setAll($event.checked)">
      {{task.name}}
    </mat-checkbox>
  </span>
  <span class="example-list-section">
    <ul>
      <li *ngFor="let subtask of task.subtasks">
        <mat-checkbox [(ngModel)]="subtask.completed"
                      (ngModelChange)="updateAllComplete()">
          {{subtask.name}}
        </mat-checkbox>
      </li>
    </ul>
  </span>
</section>
<form action="" class="example-form">
  <div *ngFor="let lang of task.subtasks; let i = index">
    <div *ngIf="lang.completed">
      <h3>{{lang.name}}</h3>
      <mat-form-field class="example-full-width">
        <mat-label>Title</mat-label>
        <input matInput name="{{lang.name}}-title" [(ngModel)]="task.subtasks[i].title" placeholder="Ex. Special discount" >
      </mat-form-field>
      <mat-form-field class="example-full-width">
        <mat-label>Body</mat-label>
        <input matInput name="{{lang.name}}-body" [(ngModel)]="task.subtasks[i].body" placeholder="Ex. Get 300 Coins and 1 swap for free !" >
      </mat-form-field>
    </div>

  </div>
    <button (click)="send()" class="btn">Send now !</button>
</form>
