import { Component, OnInit } from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {AngularFireFunctions} from '@angular/fire/functions';
import {DialogService} from '../../core/dialog.service';

@Component({
  selector: 'app-set-admin',
  templateUrl: './set-admin.component.html',
  styleUrls: ['./set-admin.component.scss']
})
export class SetAdminComponent implements OnInit {

  uid = '';
  isAdmin = true;

  constructor(private cf: AngularFireFunctions,
              private snackBar: MatSnackBar,
              private dialog: DialogService) { }

  ngOnInit(): void {
  }

  setAdmin(): void {
    this.dialog.openDialog().then(() => {
      let obs;
      if (this.uid !== ''){
        const callable = this.cf.httpsCallable('setAdminUser');
        obs = callable({uid: this.uid, isAdmin: this.isAdmin});
      }

      obs.subscribe(() => {
        this.openSnackBar('user ' + this.uid + ' successfully set as admin = ' + this.isAdmin);
      }, error => {
        console.log(error);
        this.openSnackBar('Error: ' + error.message);
      });
    });
  }

  openSnackBar(msg): void {
    this.snackBar.open(msg, null, {duration: 3000});
  }

}
