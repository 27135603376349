import { Injectable } from '@angular/core';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';
import {AngularFireAuth} from '@angular/fire/auth';
import firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  isAuth = false;

  isAuthSubject = new Subject<boolean>();

  constructor(private router: Router,
              private auth: AngularFireAuth) { }


  emitAuth(): void{
    this.isAuthSubject.next(this.isAuth);
  }


  async connectWithGoogle(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider()).then(res => {
        firebase.auth().currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            // Confirm the user is an Admin.
            if (!!idTokenResult.claims.admin) {
              this.isAuth = true;
              this.emitAuth();
              this.router.navigate(['/']);
              resolve();
            } else {
              // Show regular user UI.
              reject();
            }
          })
          .catch((error) => {
            console.log(error);
            reject();
          });
      }).catch(() => {
        reject();
      });
    });
  }

}
