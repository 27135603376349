<h1>Edit GamePlay Configs</h1>

<form action="" class="example-form">
  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        Tournament
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div *ngFor="let mode of rules.tournament.gameModes; let i = index">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{mode.gameModeName}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="sp-between">
          <mat-form-field class="example-full-width">
            <mat-label>Time WaitingForPlayers</mat-label>
            <input matInput name="{{mode.gameModeName}}-timeWaitingForPlayers" [(ngModel)]="rules.tournament.gameModes[i].timeWaitingForPlayers" type="number">
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <mat-label>Time To Bet</mat-label>
            <input matInput name="{{mode.gameModeName}}-timeToBet" [(ngModel)]="rules.tournament.gameModes[i].timeToBet" type="number">
          </mat-form-field>
        </div>
        <div class="sp-between">
          <mat-form-field class="example-full-width">
            <mat-label>Time Per Round</mat-label>
            <input matInput name="{{mode.gameModeName}}-timePerRound" [(ngModel)]="rules.tournament.gameModes[i].timePerRound" type="number">
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <mat-label>time Per Game</mat-label>
            <input matInput name="{{mode.gameModeName}}-timePerGame" [(ngModel)]="rules.tournament.gameModes[i].timePerGame" type="number">
          </mat-form-field>
        </div>
        <mat-form-field class="example-full-width">
          <mat-label>number Of Rounds</mat-label>
          <input matInput (ngModelChange)="changeNbrOfRoundsTournament(i, $event)" name="{{mode.gameModeName}}-numberOfRounds" [(ngModel)]="rules.tournament.gameModes[i].numberOfRounds" type="number">
        </mat-form-field>
        <div>
          <div class="sp-between" *ngFor="let round of rules.tournament.gameModes[i].roundsComposition; let j = index">
            <h3>Round {{j + 1}}</h3>
            <mat-form-field class="example-full-width">
              <mat-label>Player concerned</mat-label>
              <mat-select name="{{mode.gameModeName}}-player-concerned-round-{{j}}" [(ngModel)]="rules.tournament.gameModes[i].roundsComposition[j].concernedPlayer">
                <mat-option value="balanced">balanced</mat-option>
                <mat-option value="player1">player1</mat-option>
                <mat-option value="player2">player2</mat-option>
                <mat-option value="player3">player3</mat-option>
                <mat-option value="player4">player4</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="example-full-width">
              <mat-label>Variant</mat-label>
              <mat-select name="{{mode.gameModeName}}-variant-round-{{j}}" [(ngModel)]="rules.tournament.gameModes[i].roundsComposition[j].variant" type="number">
                <mat-option value="classic">classic</mat-option>
                <mat-option value="forbidden_interval">forbidden_interval</mat-option>
                <mat-option value="first_show">first_show</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Bet Ranges
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div *ngFor="let range of rules.tournament.gameModes[i].betRanges; let j = index">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Range {{j + 1}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="sp-between">
                <mat-form-field class="example-full-width">
                  <mat-label>Min</mat-label>
                  <input matInput name="{{mode.gameModeName}}-bet-range-{{j}}-min" [(ngModel)]="rules.tournament.gameModes[i].betRanges[j].min" type="number">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Max</mat-label>
                  <input matInput name="{{mode.gameModeName}}-bet-range-{{j}}-max" [(ngModel)]="rules.tournament.gameModes[i].betRanges[j].max" type="number">
                </mat-form-field>
              </div>
              <div class="sp-between">
                <mat-form-field class="example-full-width">
                  <mat-label>Starting Stack</mat-label>
                  <input matInput name="{{mode.gameModeName}}-bet-range-{{j}}-starting-stack" [(ngModel)]="rules.tournament.gameModes[i].betRanges[j].startingStack" type="number">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Buy-In Value</mat-label>
                  <input matInput name="{{mode.gameModeName}}-bet-range-{{j}}-buyin" [(ngModel)]="rules.tournament.gameModes[i].betRanges[j].buyInValue" type="number">
                </mat-form-field>
              </div>
              <div class="sp-between">
                <mat-form-field class="example-full-width" *ngFor="let prize of rules.tournament.gameModes[i].betRanges[j].winPrices; let k = index" class="sp-between">
                  <mat-label>Prize {{k + 1}}</mat-label>
                  <input matInput name="tournament-{{mode.gameModeName}}-bet-range{{j}}-winPrize-{{k}}" [(ngModel)]="rules.tournament.gameModes[i].betRanges[j].winPrices[k]" type="number">
                </mat-form-field>
              </div>
              <h5>Forbidden Intervals</h5>
              <div *ngFor="let interval of rules.tournament.gameModes[i].betRanges[j].forbiddenIntervals; let k = index">
                <div class="sp-between">
                  <mat-form-field class="example-full-width">
                    <mat-label>Min</mat-label>
                    <input matInput name="forbidden-interval-{{i}}-{{j}}-{{k}}-min" [(ngModel)]="rules.tournament.gameModes[i].betRanges[j].forbiddenIntervals[k].min" type="number">
                  </mat-form-field>
                  <mat-form-field class="example-full-width">
                    <mat-label>Max</mat-label>
                    <input matInput name="forbidden-interval-{{i}}-{{j}}-{{k}}-max" [(ngModel)]="rules.tournament.gameModes[i].betRanges[j].forbiddenIntervals[k].max" type="number">
                  </mat-form-field>
                </div>
              </div>
            </mat-expansion-panel>
          </div>
        </mat-expansion-panel>
      </mat-expansion-panel>
    </div>
  </mat-expansion-panel>



  <mat-expansion-panel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        FaceToFace
      </mat-panel-title>
    </mat-expansion-panel-header>
      <div *ngFor="let mode of rules.faceToFace.gameModes; let i = index">
        <h3>{{mode.gameModeName}}</h3>
        <div class="sp-between">
          <mat-form-field class="example-full-width">
            <mat-label>Time WaitingForPlayers</mat-label>
            <input matInput name="faceToFace-{{mode.gameModeName}}-timeWaitingForPlayers" [(ngModel)]="rules.faceToFace.gameModes[i].timeWaitingForPlayers" type="number">
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <mat-label>Time To Bet</mat-label>
            <input matInput name="faceToFace-{{mode.gameModeName}}-timeToBet" [(ngModel)]="rules.faceToFace.gameModes[i].timeToBet" type="number">
          </mat-form-field>
        </div>
        <div class="sp-between">
          <mat-form-field class="example-full-width">
            <mat-label>Time Per Round</mat-label>
            <input matInput name="faceToFace-{{mode.gameModeName}}-timePerRound" [(ngModel)]="rules.faceToFace.gameModes[i].timePerRound" type="number">
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <mat-label>time Per Game</mat-label>
            <input matInput name="faceToFace-{{mode.gameModeName}}-timePerGame" [(ngModel)]="rules.faceToFace.gameModes[i].timePerGame" type="number">
          </mat-form-field>
        </div>
        <mat-form-field class="example-full-width">
          <mat-label>number Of Rounds</mat-label>
          <input matInput (ngModelChange)="changeNbrOfRoundsTournament(i, $event)" name="faceToFace-{{mode.gameModeName}}-numberOfRounds" [(ngModel)]="rules.faceToFace.gameModes[i].numberOfRounds" type="number">
        </mat-form-field>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Bet Ranges
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div *ngFor="let range of rules.faceToFace.gameModes[i].betRanges; let j = index">
            <mat-expansion-panel>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Range {{j + 1}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="sp-between">
                <mat-form-field class="example-full-width">
                  <mat-label>Min</mat-label>
                  <input matInput name="{{mode.gameModeName}}-bet-range-{{j}}-min" [(ngModel)]="rules.faceToFace.gameModes[i].betRanges[j].min" type="number">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Max</mat-label>
                  <input matInput name="{{mode.gameModeName}}-bet-range-{{j}}-max" [(ngModel)]="rules.faceToFace.gameModes[i].betRanges[j].max" type="number">
                </mat-form-field>
              </div>
              <div class="sp-between">
                <mat-form-field class="example-full-width">
                  <mat-label>Starting Stack</mat-label>
                  <input matInput name="{{mode.gameModeName}}-bet-range-{{j}}-starting-stack" [(ngModel)]="rules.faceToFace.gameModes[i].betRanges[j].startingStack" type="number">
                </mat-form-field>
                <mat-form-field class="example-full-width">
                  <mat-label>Buy-In Value</mat-label>
                  <input matInput name="{{mode.gameModeName}}-bet-range-{{j}}-buyin" [(ngModel)]="rules.faceToFace.gameModes[i].betRanges[j].buyInValue" type="number">
                </mat-form-field>
              </div>
              <div class="sp-between">
                <mat-form-field class="example-full-width" *ngFor="let prize of rules.faceToFace.gameModes[i].betRanges[j].winPrices; let k = index" class="sp-between">
                  <mat-label>Prize {{k + 1}}</mat-label>
                  <input matInput name="faceToFace-{{mode.gameModeName}}-bet-range{{j}}-winPrize-{{k}}" [(ngModel)]="rules.faceToFace.gameModes[i].betRanges[j].winPrices[k]" type="number">
                </mat-form-field>
              </div>
              <h5>Forbidden Intervals</h5>
              <div *ngFor="let interval of rules.faceToFace.gameModes[i].betRanges[j].forbiddenIntervals; let k = index">
                <div class="sp-between">
                  <mat-form-field class="example-full-width">
                    <mat-label>Min</mat-label>
                    <input matInput name="forbidden-interval-{{i}}-{{j}}-{{k}}-min" [(ngModel)]="rules.faceToFace.gameModes[i].betRanges[j].forbiddenIntervals[k].min" type="number">
                  </mat-form-field>
                  <mat-form-field class="example-full-width">
                    <mat-label>Max</mat-label>
                    <input matInput name="forbidden-interval-{{i}}-{{j}}-{{k}}-max" [(ngModel)]="rules.faceToFace.gameModes[i].betRanges[j].forbiddenIntervals[k].max" type="number">
                  </mat-form-field>
                </div>
              </div>
            </mat-expansion-panel>
          </div>
        </mat-expansion-panel>
      </div>
  </mat-expansion-panel>



  <mat-expansion-panel style="margin-bottom: 15px">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Private Table
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="sp-between">
      <mat-form-field class="example-full-width" *ngFor="let turn of rules.privateTable.turns; let i = index">
        <mat-label>number Of Rounds</mat-label>
        <input matInput name="private-table-turn-{{i}}" [(ngModel)]="rules.privateTable.turns[i]" type="number">
      </mat-form-field>
    </div>
  </mat-expansion-panel>


  <div class="sp-between" >
    <button class="btn" (click)="updateConfigs()">SAVE</button>
  </div>

</form>
