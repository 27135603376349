import { Component, OnInit } from '@angular/core';
import {AppConfigRules, emptyConfig} from './rules';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireFunctions} from '@angular/fire/functions';
import {DialogService} from '../../core/dialog.service';
import {MatSnackBar} from "@angular/material/snack-bar";



@Component({
  selector: 'app-gameplay',
  templateUrl: './gameplay.component.html',
  styleUrls: ['./gameplay.component.scss']
})
export class GameplayComponent implements OnInit {

  rules: AppConfigRules;

  constructor(private firestore: AngularFirestore,
              private cf: AngularFireFunctions,
              private dialog: DialogService,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.rules = emptyConfig;
    this.firestore.collection('configs').doc('rules').get().subscribe(res => {
      this.rules = res.data() as AppConfigRules;
      this.editConfig();

      console.log(this.rules);
    });
  }

  changeNbrOfRoundsTournament(i: number, e: any): void {

    const newNbrOfRounds = e;
    const actualNbrOfRounds = this.rules.tournament.gameModes[i].roundsComposition.length;
    const roundsDiff = newNbrOfRounds - actualNbrOfRounds;

    console.log(newNbrOfRounds);
    console.log(actualNbrOfRounds);
    console.log(roundsDiff);

    if (roundsDiff > 0){
      for (let j = 0 ; j < roundsDiff ; j++) {
        this.rules.tournament.gameModes[i].roundsComposition.push({concernedPlayer: 'balanced', variant: 'classic'});
      }
    }
    else {
      for (let j = 0 ; j < - roundsDiff ; j ++){
        this.rules.tournament.gameModes[i].roundsComposition.pop();
      }
     }
  }

  editConfig(): void {
    for (let i = 0 ; i < this.rules.tournament.gameModes.length ; i++) {
      this.rules.tournament.gameModes[i].timeWaitingForPlayers /= 1000;
      this.rules.tournament.gameModes[i].timeToBet /= 1000;
      this.rules.tournament.gameModes[i].timePerGame /= 1000;
      this.rules.tournament.gameModes[i].timePerRound /= 1000;


      for (let j = 0 ; j < this.rules.tournament.gameModes[i].betRanges.length; j++) {
        this.rules.tournament.gameModes[i].betRanges[j].startingStack /= 100;
        this.rules.tournament.gameModes[i].betRanges[j].min /= 100;
        this.rules.tournament.gameModes[i].betRanges[j].max /= 100;
        this.rules.tournament.gameModes[i].betRanges[j].buyInValue /= 100;

        let forbiddenRules = true;

        if (!this.rules.tournament.gameModes[i].betRanges[j].forbiddenIntervals){
          this.rules.tournament.gameModes[i].betRanges[j].forbiddenIntervals = this.rules.tournament.gameModes[i].betRanges[j - 1].forbiddenIntervals;
          forbiddenRules = false;
        }
        for (let k = 0 ; k < this.rules.tournament.gameModes[i].betRanges[j].winPrices.length ; k++) {
          this.rules.tournament.gameModes[i].betRanges[j].winPrices[k] /= 100;
        }

        for (let k = 0 ; k < this.rules.tournament.gameModes[i].betRanges[j].forbiddenIntervals.length ; k++) {

          if (forbiddenRules) {
            this.rules.tournament.gameModes[i].betRanges[j].forbiddenIntervals[k].min /= 100;
            this.rules.tournament.gameModes[i].betRanges[j].forbiddenIntervals[k].max /= 100;
          }
        }
      }
    }

    for (let i = 0 ; i < this.rules.faceToFace.gameModes.length ; i++) {
      this.rules.faceToFace.gameModes[i].timeWaitingForPlayers /= 1000;
      this.rules.faceToFace.gameModes[i].timeToBet /= 1000;
      this.rules.faceToFace.gameModes[i].timePerGame /= 1000;
      this.rules.faceToFace.gameModes[i].timePerRound /= 1000;


      for (let j = 0 ; j < this.rules.faceToFace.gameModes[i].betRanges.length; j++) {
        this.rules.faceToFace.gameModes[i].betRanges[j].startingStack /= 100;
        this.rules.faceToFace.gameModes[i].betRanges[j].min /= 100;
        this.rules.faceToFace.gameModes[i].betRanges[j].max /= 100;
        this.rules.faceToFace.gameModes[i].betRanges[j].buyInValue /= 100;

        let forbiddenRules = true;

        if (!this.rules.faceToFace.gameModes[i].betRanges[j].forbiddenIntervals){
          this.rules.faceToFace.gameModes[i].betRanges[j].forbiddenIntervals = this.rules.faceToFace.gameModes[i].betRanges[j - 1].forbiddenIntervals;
          forbiddenRules = false;
        }
        for (let k = 0 ; k < this.rules.faceToFace.gameModes[i].betRanges[j].winPrices.length ; k++) {
          this.rules.faceToFace.gameModes[i].betRanges[j].winPrices[k] /= 100;
        }

        for (let k = 0 ; k < this.rules.faceToFace.gameModes[i].betRanges[j].forbiddenIntervals.length ; k++) {

          if (forbiddenRules) {
            this.rules.faceToFace.gameModes[i].betRanges[j].forbiddenIntervals[k].min /= 100;
            this.rules.faceToFace.gameModes[i].betRanges[j].forbiddenIntervals[k].max /= 100;
          }
        }
      }
    }

  }

  updateConfigs(): void {

    this.dialog.openDialog().then(() => {
      const tmpRules: AppConfigRules = emptyConfig;
      Object.assign(tmpRules, this.rules);

      for (let i = 0 ; i < tmpRules.tournament.gameModes.length ; i++) {
        tmpRules.tournament.gameModes[i].timeWaitingForPlayers *= 1000;
        tmpRules.tournament.gameModes[i].timeToBet *= 1000;
        tmpRules.tournament.gameModes[i].timePerGame *= 1000;
        tmpRules.tournament.gameModes[i].timePerRound *= 1000;

        for (let j = 0 ; j < tmpRules.tournament.gameModes[i].betRanges.length; j++) {
          tmpRules.tournament.gameModes[i].betRanges[j].startingStack *= 100;
          tmpRules.tournament.gameModes[i].betRanges[j].min *= 100;
          tmpRules.tournament.gameModes[i].betRanges[j].max *= 100;
          tmpRules.tournament.gameModes[i].betRanges[j].buyInValue *= 100;

          let forbiddenRules = true;

          if (!tmpRules.tournament.gameModes[i].betRanges[j].forbiddenIntervals){
            tmpRules.tournament.gameModes[i].betRanges[j].forbiddenIntervals = tmpRules.tournament.gameModes[i].betRanges[j - 1].forbiddenIntervals;
            forbiddenRules = false;
          }
          for (let k = 0 ; k < tmpRules.tournament.gameModes[i].betRanges[j].winPrices.length ; k++) {
            tmpRules.tournament.gameModes[i].betRanges[j].winPrices[k] *= 100;
          }

          for (let k = 0 ; k < tmpRules.tournament.gameModes[i].betRanges[j].forbiddenIntervals.length ; k++) {

            if (forbiddenRules) {
              tmpRules.tournament.gameModes[i].betRanges[j].forbiddenIntervals[k].min *= 100;
              tmpRules.tournament.gameModes[i].betRanges[j].forbiddenIntervals[k].max *= 100;
            }
          }
        }

      }


      for (let i = 0 ; i < tmpRules.faceToFace.gameModes.length ; i++) {
        tmpRules.faceToFace.gameModes[i].timeWaitingForPlayers *= 1000;
        tmpRules.faceToFace.gameModes[i].timeToBet *= 1000;
        tmpRules.faceToFace.gameModes[i].timePerGame *= 1000;
        tmpRules.faceToFace.gameModes[i].timePerRound *= 1000;

        for (let j = 0 ; j < tmpRules.faceToFace.gameModes[i].betRanges.length; j++) {
          tmpRules.faceToFace.gameModes[i].betRanges[j].startingStack *= 100;
          tmpRules.faceToFace.gameModes[i].betRanges[j].min *= 100;
          tmpRules.faceToFace.gameModes[i].betRanges[j].max *= 100;
          tmpRules.faceToFace.gameModes[i].betRanges[j].buyInValue *= 100;

          let forbiddenRules = true;

          if (!tmpRules.faceToFace.gameModes[i].betRanges[j].forbiddenIntervals){
            tmpRules.faceToFace.gameModes[i].betRanges[j].forbiddenIntervals = tmpRules.faceToFace.gameModes[i].betRanges[j - 1].forbiddenIntervals;
            forbiddenRules = false;
          }
          for (let k = 0 ; k < tmpRules.faceToFace.gameModes[i].betRanges[j].winPrices.length ; k++) {
            tmpRules.faceToFace.gameModes[i].betRanges[j].winPrices[k] *= 100;
          }

          for (let k = 0 ; k < tmpRules.faceToFace.gameModes[i].betRanges[j].forbiddenIntervals.length ; k++) {

            if (forbiddenRules) {
              tmpRules.faceToFace.gameModes[i].betRanges[j].forbiddenIntervals[k].min *= 100;
              tmpRules.faceToFace.gameModes[i].betRanges[j].forbiddenIntervals[k].max *= 100;
            }
          }
        }

      }

      const callable = this.cf.httpsCallable('updateGamesRules');
      const obs = callable({rules: tmpRules});


      obs.subscribe(res => {
          this.openSnackBar('Successfully updated GamePlay Configs');
          this.editConfig();
        },
        error => {
          this.openSnackBar(error.message);
          this.editConfig();
        });
    });
  }

  openSnackBar(msg): void {
    this.snackBar.open(msg, null, {duration: 3000});
  }

}
