import { Injectable } from '@angular/core';
import {DialogComponent} from '../shared/dialog/dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(private dialog: MatDialog) { }

  openDialog(): Promise<any> {
    return new Promise((success, fail) => {
      const dialogRef = this.dialog.open(DialogComponent, {
        height: '150px',
        width: '500px',
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          success();
        }
        else {
          fail();
        }
      });
    });
  }
}
