import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NavComponent} from './nav/nav.component';
import {LayoutModule} from '@angular/cdk/layout';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatSliderModule} from '@angular/material/slider';
import {MatInputModule} from '@angular/material/input';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatDialogModule} from '@angular/material/dialog';


import { NotificationsComponent } from './nav/notifications/notifications.component';
import { GameplayComponent } from './nav/gameplay/gameplay.component';
import {FormsModule} from '@angular/forms';

import { AngularFireFunctionsModule, REGION } from '@angular/fire/functions';
import {AngularFireModule} from '@angular/fire';
import {firebaseConfig} from '../environments/environment';
import {AngularFirestoreModule} from '@angular/fire/firestore';
import {MatOptionModule} from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatExpansionModule} from '@angular/material/expansion';
import { SetAdminComponent } from './nav/set-admin/set-admin.component';
import { DialogComponent } from './shared/dialog/dialog.component';



// @ts-ignore
// @ts-ignore
@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    NotificationsComponent,
    GameplayComponent,
    SetAdminComponent,
    DialogComponent
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        LayoutModule,
        MatToolbarModule,
        MatButtonModule,
        MatSidenavModule,
        MatIconModule,
        MatListModule,
        MatSliderModule,
        MatInputModule,
        MatCheckboxModule,
        FormsModule,
        AngularFireModule.initializeApp(firebaseConfig),
        AngularFirestoreModule,
        AngularFireFunctionsModule,
        MatOptionModule,
        MatFormFieldModule,
        MatSelectModule,
        MatExpansionModule,
        MatSnackBarModule,
        MatDialogModule
    ],
  providers: [
    { provide: REGION, useValue: 'europe-west1' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
